import React from 'react'
import styled from 'styled-components'
import { ProposalStore } from 'stores/scouts/proposal'
import { Card } from 'components/common/card'
import { Modal } from 'components/common/modal'
import { SpeechBalloon } from 'components/chat-rooms/common/message'
import { Column, Row } from 'components/common/layout'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { BaseText, H5Text } from 'components/common/text'
import { theme } from 'infra/theme'

export const ProposalMessageConfirmModal: React.FC<{
  store: ProposalStore
  onConfirm: () => void
}> = ({ store, onConfirm }) => {
  const proposalMessage =
    `${store.temp.introPerson}\n\n${store.temp.proposeReason}\n\n${store.temp.appealPoints}\n\n${store.temp.outro}`.replaceAll(
      '#{인재이름}',
      store.request.name,
    )
  return (
    <Modal
      width={390}
      onClose={() => store.setIsConfirmModalOpen(false)}
      disableCloseBtn
      disableBackgroundClick
    >
      <Content>
        <Column style={{ gap: 8 }}>
          <H5Text
            style={{
              fontWeight: theme.fontWeight.semiBold,
              textAlign: 'center',
            }}
          >
            {store.request.name}님에게 메시지를 보낼까요?
          </H5Text>
          <BaseText style={{ color: theme.color.gray1, textAlign: 'center' }}>
            아래 메시지 내용이 그대로 보내져요
          </BaseText>
        </Column>
        <SpeechBalloon
          isSending
          style={{ maxWidth: '100%', maxHeight: 200, overflow: 'auto' }}
        >
          {proposalMessage}
        </SpeechBalloon>
        <Row style={{ gap: 12 }}>
          <Button
            variant={BtnType.SUPER_LOW_OUTLINE}
            size={BtnSize.MEDIUM}
            onClick={() => store.setIsConfirmModalOpen(false)}
          >
            취소
          </Button>
          <Button
            variant={BtnType.PRIMARY2}
            size={BtnSize.MEDIUM}
            onClick={() => onConfirm()}
          >
            메시지 보내기
          </Button>
        </Row>
      </Content>
    </Modal>
  )
}

const Content = styled(Card)`
  padding: 32px;
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 32px 16px;
  }
`
