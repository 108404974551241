//TODO(son) iOS 심사후 원복
import React from 'react'
import Image from 'next/legacy/image'
import { Column, Row } from 'components/common/layout'
import { BaseText } from 'components/common/text'
import styled from 'styled-components'
// import { MyLink } from 'components/common/link'
import {
  PRIVACY_POLICY_URL,
  // SERVER_BASE_URL,
  TERMS_OF_USE_URL,
} from 'infra/constants'

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        {/* <MyLink href='/'> */}
        <Image alt='logo' src='/logo3.svg' width={128} height={24} />
        {/* </MyLink> */}
        <Column>
          <LinksContainer>
            <FooterLink
              target='_blank'
              href='https://open.kakao.com/o/g4CADOcf'
            >
              커뮤니티
            </FooterLink>
            <FooterLink target='_blank' href={TERMS_OF_USE_URL}>
              이용약관
            </FooterLink>
            <FooterLink
              target='_blank'
              href='https://accounts.kakao.com/login?continue=http%3A%2F%2Fpf.kakao.com%2F_xjWPxoK%2Fchat'
            >
              문의
            </FooterLink>
            <FooterLink target='_blank' href={PRIVACY_POLICY_URL}>
              개인정보 처리방침
            </FooterLink>
          </LinksContainer>
          <DescContainer>
            <FooterSub>
              (주)그룹바이HR | 서울특별시 강남구 역삼로 175 현승빌딩 5층
              (Tips타운 S3)
            </FooterSub>
            <FooterSub
            // onDoubleClick={() => {
            //   //TODO(son) iOS 심사 후 제거
            //   window.location.href = `${SERVER_BASE_URL}/login/fake?isUser=true`
            // }}
            >
              사업자등록 : 333-88-02226 | 직업정보제공사업 신고번호 :
              J1200020230023 | 대표 : 임진하
            </FooterSub>
            <FooterSub>
              문의 : hello_world@groupby.biz | 070-8018-7502 (평일 오전 10시 ~
              오후 6시)
            </FooterSub>
          </DescContainer>
        </Column>
        <IconContainer>
          <SnsIcon
            target='_blank'
            href='https://www.instagram.com/groupby_official/'
          >
            <Image
              alt='instagram'
              src='/instagram.svg'
              width={28}
              height={28}
            />
          </SnsIcon>
          <SnsIcon target='_blank' href='https://pf.kakao.com/_xjWPxoK/chat'>
            <Image
              alt='kakaotalk'
              src='/kakaotalk.svg'
              width={28}
              height={28}
            />
          </SnsIcon>
          <SnsIcon
            target='_blank'
            href='https://www.youtube.com/channel/UCr3XL4ChVNatcNPcIjtlQbA'
          >
            <Image alt='youtube' src='/youtube.svg' width={28} height={28} />
          </SnsIcon>
        </IconContainer>
      </FooterContent>
    </FooterContainer>
  )
}

const FooterContainer = styled(Column)`
  align-items: center;
  background-color: ${(p) => p.theme.color.white1};
  border-top: 1px solid ${(prop) => prop.theme.color.line};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 20px;
  }
`

const FooterContent = styled(Column)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: 20px;
    place-items: start;
    width: 1060px;
    padding-bottom: 60px;
  }
  padding: 40px 0;
  align-items: center;
`

const LinksContainer = styled(Row)`
  margin-bottom: 64px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin: 20px 0 10px;
  }
`

const FooterLink = styled.a`
  @media ${(p) => p.theme.deviceSize.mobile} {
    margin: 0;
    font-weight: ${(p) => p.theme.fontWeight.medium};
    font-size: ${(p) => p.theme.fontSize.default};
  }
  margin-right: 45px;
  font-size: ${(p) => p.theme.fontSize.h5};
  color: ${(p) => p.theme.color.black1};
  :last-child {
    margin-right: 0;
  }
`

const DescContainer = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    align-items: center;
  }
`

const FooterSub = styled(BaseText)`
  color: ${(p) => p.theme.color.black2};
  line-height: 1.8;
  @media ${(p) => p.theme.deviceSize.mobile} {
    text-align: center;
  }
`

const IconContainer = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    margin-top: 10px;
  }
`

const SnsIcon = styled(FooterLink)`
  margin-right: 28px;
  :last-child {
    margin-right: 0;
  }
`
