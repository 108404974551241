import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { BaseText } from 'components/common/text'
import { useRouter } from 'next/router'
import { analytics } from 'infra/analytics'

export const DesktopHeaderTabs: React.FC = () => {
  const router = useRouter()
  return (
    <MenuContainer>
      <MyLink
        href='/landing'
        onClick={() => analytics.track('click_mi_header_scouts')}
      >
        <MenuTitle isSelected={router.pathname === '/landing'}>
          스카우트 제안받기
        </MenuTitle>
      </MyLink>
      <MyLink
        href='/positions'
        onClick={() => analytics.track('click_mi_header_find_position')}
      >
        <MenuTitle isSelected={router.pathname === '/positions'}>
          채용 공고
        </MenuTitle>
      </MyLink>
      <MyLink
        href='/programs'
        onClick={() => analytics.track('click_mi_header_program')}
      >
        <MenuTitle isSelected={router.pathname === '/programs'}>
          채용 프로그램
        </MenuTitle>
      </MyLink>
      <MyLink
        newTab
        href='https://blog.naver.com/groupby07'
        onClick={() => analytics.track('click_mi_header_blog')}
      >
        <MenuTitle>블로그</MenuTitle>
      </MyLink>
    </MenuContainer>
  )
}

const MenuContainer = styled(Row)`
  margin-top: 20px;
  gap: 30px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`

const MenuTitle = styled(BaseText).attrs({
  as: 'h2',
})<{ isSelected?: boolean }>`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  height: 40px;
  :hover {
    color: ${(p) => p.theme.color.gray1};
  }
  ${(p) => p.isSelected && `border-bottom: 4px solid ${p.theme.color.primary};`}
`
