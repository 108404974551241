import { makeAutoObservable } from 'mobx'
import { mutate } from 'swr'
import {
  CareerHistory,
  CompanyForCareerHistory,
  EditableCareer,
  EditableEducation,
  EditableLanguageSkill,
  EditableOtherActivity,
  EducationHistory,
  LanguageSkill,
  OtherActivityHistory,
  ProfileFormPages,
  ScoutProfile,
  ScoutRequestParams,
  TechStack,
  WithIdLink,
  WithIdName,
  WithYearMonth,
} from 'types/common'
import { initialCareer, initialOtherActivity } from 'types/initial'
import { getUrlLastSegment } from 'utils/formatters'
import { requestToast } from 'components/common/toast'
import {
  putRequestScoutRequestMe,
  putScoutsRequestsMeIsPublished,
} from 'apis/request'
import { noticePrivateInfoDetected } from 'utils/handlers'

const NOT_COMPLETED_NOTICE = '작성 중인 카드를 완성하고 저장해주세요.'

const initialEducation: EditableEducation = {
  name: '',
  school: null,
  image: null,
  status: { id: 0, name: '' },
  isCs: false,
  major: '',
  category: { id: 0, name: '' },
  admissionYear: null,
  graduationYear: null,
  isCompleted: false,
  isCreatedByUser: true,
}

const initialLanguageSkill: EditableLanguageSkill = {
  language: null,
  level: null,
  description: '',
  isCompleted: false,
  isEdited: false,
}

export class ScoutProfileFormStore {
  form?: ScoutProfile = undefined
  languageSkills: EditableLanguageSkill[] = []
  careers: EditableCareer[] = []
  otherActivities: EditableOtherActivity[] = []
  educations: EditableEducation[] = []
  changedLinks: Array<WithIdLink | string> = ['', '']
  isSteveModalOpen: boolean = false
  isGettingCareerModalOpen: boolean = false
  // request-form 에서만 사용
  checkMessage: string = ''
  isLaterModalOpen: boolean = false
  isLoading: boolean = false
  // 내 프로필 에서만 사용
  isPreviewOpen: boolean = false
  isIntroductionEditing: boolean = false
  isExpectationEditing: boolean = false
  isJobSearchingReasonEditing: boolean = false

  constructor(form?: ScoutProfile) {
    makeAutoObservable(this)
    if (form) this.setForm(form)
  }

  get getImageUrl() {
    const image = this.form?.image
    if (!image) return ''
    if (typeof image === 'string') return image
    else return URL.createObjectURL(image)
  }

  get expectationKeywordIds() {
    return this.form?.expectation.keywords.map((x) => x.id) || []
  }

  get personalitiesKeywordIds() {
    return this.form?.personalities.map((x) => x.id) || []
  }

  get workExperiences() {
    return [...this.careers, ...this.otherActivities]
  }

  get isCareerEditing() {
    return this.workExperiences.some((item) => !item.isCompleted || item.backup)
  }

  get isEducationEditing() {
    return this.educations.some((item) => !item.isCompleted || item.backup)
  }

  get isLanguageEditing() {
    return this.languageSkills.some((item) => !item.isCompleted || item.backup)
  }

  get isLanguageEmpty() {
    if (this.languageSkills.length > 1) return false
    const first = this.languageSkills[0]
    if (!first) return true
    return !first.language && !first.level && !first.description
  }

  setMyProfileIsPublished = async (isPublished: boolean) => {
    await requestToast(putScoutsRequestsMeIsPublished({ value: isPublished }), {
      loading: '요청중',
      success: isPublished ? '프로필을 공개했어요.' : '프로필을 비공개했어요.',
    }).then(() => {
      mutate('/scouts/requests/me')
    })
  }

  publishProfileAfterConfirm = async (props: {
    isPublished: boolean
    hasCareers: boolean
    hasEducations: boolean
    hasOtherActivities: boolean
  }) => {
    setTimeout(() => {
      const needToPublish =
        !props.isPublished &&
        props.hasEducations &&
        (props.hasCareers || !!props.hasOtherActivities)
      if (
        needToPublish &&
        confirm(
          '필수 입력 정보를 모두 입력했어요.\n프로필을 공개하여 스카우트 제안을 받아보시겠어요?',
        )
      ) {
        this.setMyProfileIsPublished(true)
      }
    }, 2000)
  }

  getNeedGraduationYear(index: number) {
    const education = this.educations[index]
    return (
      education.status.name === '졸업' || education.status.name === '졸업예정'
    )
  }

  setJobSearchingReason(v: string) {
    if (!this.form) return
    this.form.jobSearchingReason = v
  }

  setIsJobSearchingReasonEditing(v: boolean) {
    this.isJobSearchingReasonEditing = v
  }

  setIsSteveModalOpen(v: boolean) {
    this.isSteveModalOpen = v
  }

  setIsGettingCareerModalOpen(v: boolean) {
    this.isGettingCareerModalOpen = v
  }

  setIsLaterModalOpen(v: boolean) {
    this.isLaterModalOpen = v
  }

  setIsLoading(v: boolean) {
    this.isLoading = v
  }

  setIsPreviewOpen(v: boolean) {
    this.isPreviewOpen = v
  }

  setIsIntroductionEditing(v: boolean) {
    this.isIntroductionEditing = v
  }

  setIsExpectationEditing(v: boolean) {
    this.isExpectationEditing = v
  }

  formatEducation(item: EducationHistory): EditableEducation {
    return {
      ...item,
      name: item.school.name || '',
      image: item.school.image,
      school: item.school.id || null,
      isCompleted: true,
      isEdited: false,
      isCreatedByUser: !!item.isCreatedByUser,
    }
  }

  toggleOtherActivityTechStacks(item: WithIdName, index: number) {
    if (
      this.otherActivities[index].techStacks
        .map((item) => item.id)
        .includes(item.id)
    ) {
      this.otherActivities[index].techStacks = this.otherActivities[
        index
      ].techStacks.filter((stack) => stack.id !== item.id)
    } else {
      this.otherActivities[index].techStacks = [
        ...this.otherActivities[index].techStacks,
        item,
      ]
    }
  }

  toggleServiceAreas(item: WithIdName, index: number) {
    if (
      this.careers[index].serviceAreas.map((item) => item.id).includes(item.id)
    ) {
      this.careers[index].serviceAreas = this.careers[
        index
      ].serviceAreas.filter((service) => service.id !== item.id)
    } else {
      if (this.careers[index].serviceAreas.length >= 5)
        return alert('최대 5개까지 선택 가능합니다.')
      this.careers[index].serviceAreas = [
        ...this.careers[index].serviceAreas,
        item,
      ]
    }
  }

  formatCareer(item: CareerHistory): EditableCareer {
    return {
      ...item,
      isCreatedByUser: !!item.company.isCreatedByUser,
      image: item.company.image,
      name: item.company.name || '',
      company: item.company.id || null,
      serviceAreas: item.company.serviceAreas,
      isStartup: item.company.isStartup,
      link: item.link,
      isCompleted: true,
      isEdited: false,
      started: {
        year: item.started.split('-')[0],
        month: item.started.split('-')[1],
      },
      ended: {
        year: item.ended.split('-')[0],
        month: item.ended.split('-')[1],
      },
      description: item.description || '',
    }
  }

  formatOtherActivity(item: OtherActivityHistory) {
    return {
      ...item,
      started: {
        year: item.started.split('-')[0],
        month: item.started.split('-')[1],
      },
      ended: {
        year: item.ended.split('-')[0],
        month: item.ended.split('-')[1],
      },
      description: item.description || '',
      isCompleted: true,
      isEdited: false,
    }
  }

  setForm(form: ScoutProfile, isRequestForm?: boolean) {
    this.form = form
    if (form.educations.length > 0)
      this.educations = form.educations.map((x) => this.formatEducation(x))
    if (form.careers.length > 0)
      this.careers = form.careers.map((item) => this.formatCareer(item))
    if (form.otherActivities.length > 0)
      this.otherActivities = form.otherActivities.map((item) =>
        this.formatOtherActivity(item),
      )
    if (form.languageSkills.length > 0)
      this.languageSkills = form.languageSkills.map((x) => ({
        ...x,
        isCompleted: true,
        isEdited: false,
      }))
    if (isRequestForm && !form.careers.length && !form.otherActivities.length)
      this.careers = [initialCareer]
    if (isRequestForm && !form.languageSkills.length)
      this.languageSkills = [initialLanguageSkill]
    this.updateLinks(form.links)
  }

  setExpectation(expectation: { description: string; keywords: WithIdName[] }) {
    if (!this.form) return
    this.form.expectation = expectation
  }

  setExpectationDescription(v: string) {
    if (!this.form) return
    this.form.expectation.description = v
  }

  togglePersonalitiesKeywords(keyword: WithIdName) {
    if (!this.form) return
    if (this.personalitiesKeywordIds.includes(keyword.id)) {
      this.form.personalities = this.form.personalities.filter(
        (x) => x.id !== keyword.id,
      )
    } else {
      if (this.form.personalities.length >= 3) return
      this.form.personalities.push(keyword)
    }
  }

  toggleExpectationKeywords(keyword: WithIdName) {
    if (!this.form) return
    if (this.expectationKeywordIds.includes(keyword.id)) {
      this.form.expectation.keywords = this.form.expectation.keywords.filter(
        (x) => x.id !== keyword.id,
      )
    } else {
      if (this.form.expectation.keywords.length >= 3) return
      this.form.expectation.keywords.push(keyword)
    }
  }

  setIntroductionDesc(v: string) {
    if (!this.form) return
    this.form.introduction = v
  }

  setIntroduction(data: { introduction: string; personalities: WithIdName[] }) {
    if (!this.form) return
    this.form.introduction = data.introduction
    this.form.personalities = data.personalities
  }

  addLink() {
    this.changedLinks = [...this.changedLinks, '']
    return this.changedLinks.length - 1 // 추가된 링크의 index
  }

  setLink(v: string, index: number) {
    this.changedLinks[index] = v
  }

  removeFile(file: WithIdLink) {
    if (!this.form) return
    this.form.files = this.form.files.filter((item) => item.id !== file.id)
  }

  addFile(file: WithIdLink) {
    if (!this.form) throw '에러가 발생했어요. 새로고침해주세요.'
    if (this.isDuplicatedFile(file.link)) throw '중복된 파일입니다.'
    this.form.files = [...this.form.files, file]
  }

  isDuplicatedFile(file: string) {
    return this.form?.files
      .map((item) => getUrlLastSegment(item.link))
      .includes(getUrlLastSegment(file))
  }

  setEducationEditingMode(index: number) {
    this.educations[index].backup = { ...this.educations[index] }
  }

  setCareerEditingMode(index: number) {
    this.careers[index].backup = { ...this.careers[index] }
  }

  removeEmptyCareer() {
    this.careers = this.careers.filter(
      (x) =>
        !(
          !x.id &&
          !x.name &&
          !x.position &&
          !x.started.year &&
          !x.started.month
        ), // 이 중 값을 입력한 게 하나도 없으면 빈 카드로 간주하고 삭제
    )
  }

  addCareer() {
    this.careers.push(initialCareer)
  }

  setCareerDescription(v: string, index: number) {
    this.careers[index].description = v
  }

  setCareerLink(v: string, index: number) {
    this.careers[index].link = v
  }

  completeCareer(index: number) {
    const career = this.careers[index]
    if (!career.name.trim()) throw '회사명을 입력해주세요.'
    if (!career.position.trim()) throw '포지션을 입력해주세요.'
    if (!career.started.year || !career.started.month)
      throw '시작일을 입력해주세요.'
    if (!career.isWorking && (!career.ended.year || !career.ended.month))
      throw '종료일을 입력해주세요.'
    if (!career.isWorking && this.validatePeriod(career.started, career.ended))
      throw '종료일이 시작일보다 빨라요.'
    if (career.id) career.isEdited = true
    career.isCompleted = true
    career.backup = undefined
  }

  setCareerCompany(v: CompanyForCareerHistory, index: number) {
    this.careers[index].isCreatedByUser = !!v.isCreatedByUser
    this.careers[index].serviceAreas = v.serviceAreas
    this.careers[index].isStartup = v.isStartup
    this.careers[index].company = v.id || null
    this.careers[index].name = v.name
    this.careers[index].image = v.image
  }

  completeEducation(index: number) {
    const education = this.educations[index]
    if (education.id) education.isEdited = true
    education.isCompleted = true
    education.backup = undefined
  }

  setCareerIsStartup(v: boolean, index: number) {
    this.careers[index].isStartup = v
  }

  setCareerPosition(v: string, index: number) {
    this.careers[index].position = v
  }

  setCareerIsWorking(v: boolean, index: number) {
    this.careers[index].isWorking = v
    if (v) {
      this.careers[index].ended.year = ''
      this.careers[index].ended.month = ''
    }
  }

  setCareerStartedYear(v: string, index: number) {
    this.careers[index].started.year = v
  }

  setCareerStartedMonth(v: string, index: number) {
    this.careers[index].started.month = v
  }

  setCareerEndedYear(v: string, index: number) {
    this.careers[index].ended.year = v
  }

  setCareerEndedMonth(v: string, index: number) {
    this.careers[index].ended.month = v
  }

  removeCareer(index: number) {
    this.careers.splice(index, 1)
  }

  selectTechStack(v: TechStack, index: number) {
    if (this.careers[index].techStacks.map((item) => item.id).includes(v.id)) {
      this.removeTechStack(v.id, index)
    } else {
      this.careers[index].techStacks = [...this.careers[index].techStacks, v]
    }
  }

  removeTechStack(v: number, index: number) {
    this.careers[index].techStacks = this.careers[index].techStacks.filter(
      (item) => item.id !== v,
    )
  }

  cancelCareer(index: number) {
    const backup = this.careers[index].backup
    if (backup) this.careers[index] = { ...backup }
    else this.removeCareer(index)
  }

  setOtherActivityEditingMode(index: number) {
    this.otherActivities[index].backup = { ...this.otherActivities[index] }
  }

  addOtherActivity() {
    this.otherActivities.push(initialOtherActivity)
  }

  setOtherActivityTitle(v: string, index: number) {
    this.otherActivities[index].title = v
  }

  setOtherActivityDescription(v: string, index: number) {
    this.otherActivities[index].description = v
  }

  setOtherActivityStartedYear(v: string, index: number) {
    this.otherActivities[index].started.year = v
  }

  setOtherActivityStartedMonth(v: string, index: number) {
    this.otherActivities[index].started.month = v
  }

  setOtherActivityEndedYear(v: string, index: number) {
    this.otherActivities[index].ended.year = v
  }

  setOtherActivityEndedMonth(v: string, index: number) {
    this.otherActivities[index].ended.month = v
  }

  setOtherActivityIsOnGoing(v: boolean, index: number) {
    this.otherActivities[index].isOnGoing = v
    if (v) {
      this.otherActivities[index].ended.year = ''
      this.otherActivities[index].ended.month = ''
    }
  }

  removeOtherActivity(index: number) {
    this.otherActivities.splice(index, 1)
  }

  cancelOtherActivity(index: number) {
    const backup = this.otherActivities[index].backup
    if (backup) this.otherActivities[index] = { ...backup }
    else this.removeOtherActivity(index)
  }

  completeOtherActivity(index: number) {
    const otherActivity = this.otherActivities[index]
    if (!otherActivity.title.trim()) throw '활동명을 입력해주세요.'
    if (!otherActivity.description.trim()) throw '설명을 입력해주세요.'
    if (!otherActivity.started.year || !otherActivity.started.month)
      throw '날짜/시작일을 입력해주세요.'
    if (
      (otherActivity.ended.year && !otherActivity.ended.month) ||
      (!otherActivity.ended.year && otherActivity.ended.month)
    )
      throw '종료일을 입력해주세요.'
    if (
      !otherActivity.isOnGoing &&
      otherActivity.ended.year &&
      this.validatePeriod(otherActivity.started, otherActivity.ended)
    )
      throw '종료일이 시작일보다 빨라요.'
    if (otherActivity.id) otherActivity.isEdited = true
    otherActivity.isCompleted = true
    otherActivity.backup = undefined
  }

  // isCareer는 '경력'으로 바뀌어야할 때 true
  toggleWorkExperience(isCareer: boolean, index: number) {
    if (isCareer) {
      this.removeOtherActivity(index)
      this.addCareer()
    } else {
      this.removeCareer(index)
      this.addOtherActivity()
    }
  }

  setLanguageSkillEditingMode(index: number) {
    this.languageSkills[index].backup = { ...this.languageSkills[index] }
  }

  setLanguage(language: WithIdName, index: number) {
    this.languageSkills[index].language = language
  }

  setLanguageLevel(languageLevel: WithIdName, index: number) {
    this.languageSkills[index].level = languageLevel
  }

  setLanguageDescription(description: string, index: number) {
    this.languageSkills[index].description = description
  }

  addLanguageSkill() {
    this.languageSkills.push(initialLanguageSkill)
  }

  addEducations() {
    this.educations.push(initialEducation)
  }

  removeLanguageSkill(index: number) {
    this.languageSkills.splice(index, 1)
  }

  removeEducation(index: number) {
    this.educations.splice(index, 1)
  }

  cancelLanguageSkill(index: number) {
    const backup = this.languageSkills[index].backup
    if (backup) this.languageSkills[index] = { ...backup }
    else this.removeLanguageSkill(index)
  }

  cancelEducation(index: number) {
    const backup = this.educations[index].backup
    if (backup) this.educations[index] = { ...backup }
    else this.removeEducation(index)
  }

  completeLanguageSkill(index: number) {
    const languageSkill = this.languageSkills[index]
    if (!languageSkill.language) throw '언어를 선택해주세요.'
    if (!languageSkill.level) throw '구사 수준을 선택해주세요.'
    if (languageSkill.id) languageSkill.isEdited = true
    languageSkill.isCompleted = true
    languageSkill.backup = undefined
  }

  setImage(image: File | string) {
    if (!this.form) return
    this.form.image = image
  }

  setFinalEducationNameWithImage(
    obj: { id?: number; image: string | null; name: string },
    index: number,
  ) {
    if (!this.educations[index]) return
    this.educations[index].school = obj.id || null
    this.educations[index].name = obj.name
    this.educations[index].image = obj.image
  }

  setFinalEducationMajor(v: string, index: number) {
    if (!this.educations[index]) return
    this.educations[index].major = v
  }

  setFinalEducationIsCs(v: boolean, index: number) {
    if (!this.educations[index]) return
    this.educations[index].isCs = v
  }

  setFinalEducationStatus(v: WithIdName, index: number) {
    if (!this.educations[index]) return
    this.educations[index].status = v
    if (!this.getNeedGraduationYear(index))
      this.educations[index].graduationYear = null
  }

  setEducationCategory(v: WithIdName, index: number) {
    if (!this.educations[index]) return
    this.educations[index].category = v
  }

  setFinalEducationAdmissionYear(v: string, index: number) {
    if (!this.educations[index]) return
    this.educations[index].admissionYear = v
  }

  setFinalEducationGraduationYear(v: string, index: number) {
    if (!this.educations[index]) return
    this.educations[index].graduationYear = v
  }

  setCheckAlertMessage(v: string) {
    this.checkMessage = v
  }

  validateEducation(index: number) {
    const education = this.educations[index]
    if (!education.category?.id) throw '분류를 선택해주세요.'
    if (!education.name || !education.name.trim())
      throw '학교명을 입력해주세요.'
    if (!education.major.trim()) throw '학과명을 입력해주세요.'
    if (!education.status.id) throw '상태를 선택해주세요.'
    if (!education.admissionYear) throw '입학 연도를 선택해주세요.'
    if (this.getNeedGraduationYear(index) && !education.graduationYear)
      throw '졸업 연도를 선택해주세요.'
    if (
      education.graduationYear &&
      new Date(education.admissionYear) > new Date(education.graduationYear)
    )
      throw '종료일이 시작일보다 빨라요.'
  }

  validateResume() {
    const notEmptyLinks = this.changedLinks
      .map((item) => (typeof item === 'string' ? item : item.link))
      .filter((item) => item.trim() !== '')
    const linksSet = new Set(notEmptyLinks)
    const isDuplicate = linksSet.size < notEmptyLinks.length
    if (isDuplicate) throw '중복된 링크가 존재합니다.'
    const addedLinks = notEmptyLinks.filter(
      (link) =>
        this.form?.links.find((item) => item.link === link) === undefined,
    )
    const removedLinks = this.form?.links
      .filter(
        (item) =>
          notEmptyLinks.find((link) => link === item.link) === undefined,
      )
      .map((item) => item.id)
    return {
      files: this.form?.files.map((item) => item.id) || [],
      addedLinks,
      removedLinks,
    }
  }

  validatePeriod(started: WithYearMonth, ended: WithYearMonth) {
    return (
      new Date(started.year + '-' + started.month) >
      new Date(ended.year + '-' + ended.month)
    )
  }

  // request-form과 기대하는 점에서만 사용
  checkData(page: ProfileFormPages): Partial<ScoutRequestParams> | undefined {
    switch (page) {
      case 'education':
        if (this.isEducationEditing) throw NOT_COMPLETED_NOTICE
        break
      case 'career':
        if (this.isCareerEditing) throw NOT_COMPLETED_NOTICE
        break
      case 'resume':
        return this.validateResume()
      case 'introduce':
        return {
          introduction: this.form?.introduction || '',
          personalities: this.personalitiesKeywordIds,
        }
      case 'expectation':
        return {
          expectation: {
            description: this.form?.expectation.description || '',
            keywords: this.expectationKeywordIds,
          },
        }
      case 'job-searching-reason':
        return { jobSearchingReason: this.form?.jobSearchingReason || '' }
      case 'language':
        // 선택 항목이라 아무것도 안 쓴 경우 그냥 페이지 넘길 수 있어야함
        if (!this.isLanguageEmpty && this.isLanguageEditing)
          throw NOT_COMPLETED_NOTICE
        break
      case 'image':
        return {} // updateData가 실행되기 위해 payload return이 필요해서 예외적으로 처리
    }
  }

  getEducationPayload(education: EditableEducation) {
    return {
      status: education.status.id,
      isCs: education.isCs,
      major: education.major.trim(),
      admissionYear: education.admissionYear,
      graduationYear: education.graduationYear,
      name: education.name ? education.name.trim() : null,
      school: education.school,
      category: education.category ? education.category.id : null,
    }
  }

  getCareerPayload(career: EditableCareer) {
    return {
      id: career.id,
      name: career.name.trim(),
      company: career.company,
      serviceAreas: career.serviceAreas.map((item) => item.id),
      isStartup: career.isStartup,
      link: career.link || null,
      position: career.position.trim(),
      started: career.started.year + '-' + career.started.month,
      ended: career.isWorking
        ? ''
        : career.ended.year + '-' + career.ended.month,
      isWorking: career.isWorking,
      techStacks: career.techStacks.map((t) => t.id),
      description: career.description,
      isVerified: career.isVerified,
      isCreatedByUser: career.isCreatedByUser,
    }
  }

  getOtherActivityPayload(otherActivity: EditableOtherActivity) {
    // OtherActivityHistory에 없는 키 같이 보내면 에러남
    return {
      title: otherActivity.title,
      techStacks: otherActivity.techStacks.map((item) => item.id),
      description: otherActivity.description,
      started: otherActivity.started.year + '-' + otherActivity.started.month,
      ended:
        !otherActivity.ended.year || !otherActivity.ended.month // isOnGoing 아니라도 종료일은 입력하지 않을 수 있음
          ? ''
          : otherActivity.ended.year + '-' + otherActivity.ended.month,
      isOnGoing: otherActivity.isOnGoing,
    }
  }

  async saveEachEducationCard(item: EditableEducation) {
    const payload: Partial<ScoutRequestParams> = {
      addedEducations: [this.getEducationPayload(item)],
    }
    if (item.id) payload.removedEducations = [item.id] // 수정하여 저장하는 상황
    await putRequestScoutRequestMe(payload)
    const { data: profile } = await mutate('/scouts/requests/me')
    this.updateEducations(profile.educations)
  }

  async removeEachEducationCard(index: number, item: EditableEducation) {
    if (item.id)
      await putRequestScoutRequestMe({ removedEducations: [item.id] })
    this.removeEducation(index)
    mutate('/scouts/requests/me')
  }

  updateEducations(educations: EducationHistory[]) {
    this.educations = educations
      .map((x) => {
        const mostRecent =
          this.educations.find(
            (existing) => existing.id === x.id, // 업데이트하기 전에도 원래 있었던 건 기존 것을 살림 (수정 중일 수도 있으므로)
          ) || this.formatEducation(x)
        return mostRecent
      })
      .concat(this.educations.filter((x) => !x.isCompleted)) // 새로 추가했는데 아직 완료되지 않은 것
  }

  async saveEachCareerCard(item: EditableCareer) {
    const payload: Partial<ScoutRequestParams> = {
      addedCareers: [this.getCareerPayload(item)],
    }
    if (item.id) payload.removedCareers = [item.id] // 수정하여 저장하는 상황
    const { data } = await putRequestScoutRequestMe(payload)
    noticePrivateInfoDetected(data, '직무 경험')
    const { data: profile } = await mutate('/scouts/requests/me')
    this.updateCareers(profile.careers)
    data?.isLinkAdded && this.updateLinksAfterDetecting(profile.links)
  }

  async removeEachCareerCard(index: number, item: EditableCareer) {
    if (item.id) await putRequestScoutRequestMe({ removedCareers: [item.id] })
    this.removeCareer(index)
  }

  updateCareers(careers: CareerHistory[]) {
    this.careers = careers
      .map((x) => {
        const mostRecent =
          this.careers.find(
            (existing) => existing.id === x.id, // 업데이트하기 전에도 원래 있었던 건 기존 것을 살림 (수정 중일 수도 있으므로)
          ) || this.formatCareer(x)
        return mostRecent
      })
      .concat(this.careers.filter((x) => !x.isCompleted)) // 새로 추가했는데 아직 완료되지 않은 것
  }

  async saveEachOtherActivityCard(item: EditableOtherActivity) {
    const payload: Partial<ScoutRequestParams> = {
      addedOtherActivities: [this.getOtherActivityPayload(item)],
    }
    if (item.id) payload.removedOtherActivities = [item.id] // 수정하여 저장하는 상황
    const { data } = await putRequestScoutRequestMe(payload)
    noticePrivateInfoDetected(data, '직무 경험')
    const { data: profile } = await mutate('/scouts/requests/me')
    this.updateOtherActivities(profile.otherActivities)
    data?.isLinkAdded && this.updateLinksAfterDetecting(profile.links)
  }

  async removeEachOtherActivityCard(
    index: number,
    item: EditableOtherActivity,
  ) {
    if (item.id)
      await putRequestScoutRequestMe({ removedOtherActivities: [item.id] })
    this.removeOtherActivity(index)
  }

  updateOtherActivities(otherActivities: OtherActivityHistory[]) {
    this.otherActivities = otherActivities
      .map((x) => {
        const mostRecent =
          this.otherActivities.find(
            (existing) => existing.id === x.id, // 업데이트하기 전에도 원래 있었던 건 기존 것을 살림 (수정 중일 수도 있으므로)
          ) || this.formatOtherActivity(x)
        return mostRecent
      })
      .concat(this.otherActivities.filter((x) => !x.isCompleted)) // 새로 추가했는데 아직 완료되지 않은 것
  }

  async saveEachLanguageCard(item: EditableLanguageSkill) {
    try {
      const payload: Partial<ScoutRequestParams> = {
        addedLanguageSkills: [
          {
            language: item.language?.id || 0,
            level: item.level?.id || 0,
            description: item.description.trim(),
          },
        ],
      }
      if (item.id) payload.removedLanguageSkills = [item.id]
      const { data } = await putRequestScoutRequestMe(payload)
      noticePrivateInfoDetected(data, '외국어 능력')
      const { data: profile } = await mutate('/scouts/requests/me')
      this.updateLanguageSkills(profile.languageSkills)
      data?.isLinkAdded && this.updateLinksAfterDetecting(profile.links)
    } catch (e) {
      alert(e)
    }
  }

  async removeEachLanguageCard(index: number, item: EditableLanguageSkill) {
    try {
      if (!confirm('정말 삭제할까요?')) return
      // 내 프로필 페이지이므로 삭제할 때는 무조건 id 존재할 것이지만 타입상 확인해줌
      if (item.id)
        await putRequestScoutRequestMe({ removedLanguageSkills: [item.id] })
      this.removeLanguageSkill(index)
      mutate('/scouts/requests/me')
    } catch (e) {
      alert(e)
    }
  }

  updateLanguageSkills(languageSkills: LanguageSkill[]) {
    this.languageSkills = languageSkills
      .map((x) => {
        const mostRecent = this.languageSkills.find(
          (existing) => existing.id === x.id, // 업데이트하기 전에도 원래 있었던 건 기존 것을 살림 (수정 중일 수도 있으므로)
        ) || { ...x, isCompleted: true, isEdited: false }
        return mostRecent
      })
      .concat(this.languageSkills.filter((x) => !x.isCompleted)) // 새로 추가했는데 아직 완료되지 않은 것
  }

  updateLinks(links: WithIdLink[]) {
    if (this.form) this.form.links = links
    this.changedLinks = links.length ? links : ['']
    if (links[0]?.link.includes('github')) return
    this.changedLinks.unshift('')
  }

  // 이탈 가능 정보로 감지된 링크가 첨부자료에 추가된 후 업데이트
  updateLinksAfterDetecting(links: WithIdLink[]) {
    if (!this.form) return
    links.forEach((updated) => {
      if (this.form?.links.find((existing) => updated.id === existing.id))
        return // 기존에 없는데 새로 생긴 것, 즉 이탈 정보로 감지되어 링크에 자동 추가된 것이 있을 때만 changedLinks에 추가함
      this.changedLinks.push(updated)
    })
    this.form.links = links
  }

  publish() {
    putScoutsRequestsMeIsPublished({ value: true }).then(() => {
      if (this.form) this.form.isPublished = true
    })
  }
}
