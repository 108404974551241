import React from 'react'
import styled from 'styled-components'
import { useStores } from 'stores/globals'
import { observer } from 'mobx-react'
import { Column, Row } from 'components/common/layout'
import { kakao } from 'infra/kakao'
import Image from 'next/legacy/image'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'infra/constants'
import { Modal } from 'components/common/modal'
import { BtnSize, BtnType, Button } from 'components/common/button'
import Link from 'next/link'
import { MyLink } from 'components/common/link'

export const KakaoLoginBtn = () => (
  <Button size={BtnSize.LARGE} variant={BtnType.KAKAO} onClick={kakao.login}>
    <Row style={{ justifyContent: 'center', gap: 10 }}>
      <Image
        src='/kakao-login-btn.svg'
        alt='kakao-login-button'
        width={20}
        height={20}
        priority
      />
      카카오로 3초만에 로그인하기
    </Row>
  </Button>
)

export const LoginForm: React.FCC = ({ children }) => {
  return (
    <LoginFormContainer>
      {children}
      <Bottom>
        <KakaoLoginBtn />
        <p style={{ lineHeight: 1.4 }}>
          <MyLink href={PRIVACY_POLICY_URL} newTab>
            개인정보 처리방침
          </MyLink>
          ,{' '}
          <Link href={TERMS_OF_USE_URL} target='_blank' rel='noreferrer'>
            이용약관
          </Link>
          <br />위 내용을 모두 확인하였으며, 회원가입에 동의합니다.
        </p>
      </Bottom>
    </LoginFormContainer>
  )
}

export const LoginModal: React.FC = observer(() => {
  const { loginStore } = useStores()
  if (!loginStore.isModalOpen) return null
  return (
    <Modal width={450} onClose={() => loginStore.setIsModalOpen(false)}>
      <Login />
    </Modal>
  )
})

export const Login: React.FC = observer(() => {
  return (
    <LoginForm>
      <Title>
        <h1>
          스타트업 가고 싶을 땐,
          <br />
          <span>그룹바이</span>
        </h1>
      </Title>
      <Desc>
        <p>
          VC 투자받은 검증된 스타트업에
          <br />
          지금 바로 합류해보세요!
        </p>
      </Desc>
    </LoginForm>
  )
})

const LoginFormContainer = styled(Column)`
  padding: 50px 0;
`

const Title = styled.div`
  padding: 0 30px;

  h1 {
    font-size: ${(p) => p.theme.fontSize.h2};
    font-weight: bold;
    line-height: 35px;
    @media ${(p) => p.theme.deviceSize.mobile} {
      font-size: ${(p) => p.theme.fontSize.h4};
      line-height: 28px;
    }
  }

  span {
    color: ${(p) => p.theme.color.primary};
  }
`

const Desc = styled.div`
  padding: 20px 30px 160px;
  p {
    font-size: ${(p) => p.theme.fontSize.h5};
    color: ${(p) => p.theme.color.black2};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 20px 30px 100px;
    p {
      font-size: ${(p) => p.theme.fontSize.desc};
    }
  }
`

const Bottom = styled.div`
  padding: 0 30px;
  p {
    padding: 20px 0;
    text-align: center;
    line-height: normal;
    font-size: ${(p) => p.theme.fontSize.small};
    color: ${(p) => p.theme.color.gray2};

    a {
      color: ${(p) => p.theme.color.primary};
      text-decoration: underline;
    }
  }
`
