import React from 'react'
import { Row } from 'components/common/layout'
import { useRouter } from 'next/router'
import { DashboardMenuTitleWithLink } from 'components/misc/header/link-with-menu-title'
import { useDashboardApplicationsStatus } from 'apis/hooks'
import { AlertBadge } from 'components/common/badge'

export const DashboardHeaderTabMenu: React.FC = () => {
  const router = useRouter()
  const { data: applicationStatus } = useDashboardApplicationsStatus()

  return (
    <Row>
      <DashboardMenuTitleWithLink
        href='/dashboard/scouts/search/new-profile'
        isSelected={router.pathname.includes('/dashboard/scouts')}
      >
        스카우트 관리
      </DashboardMenuTitleWithLink>
      <DashboardMenuTitleWithLink
        href='/dashboard/applicants'
        isSelected={router.pathname.includes('/dashboard/applicants')}
      >
        <div style={{ position: 'relative' }}>
          {applicationStatus && applicationStatus[0].count > 0 && (
            <AlertBadge top={-10} right={-10} size={20}>
              {applicationStatus[0].count}
            </AlertBadge>
          )}
          지원자 관리
        </div>
      </DashboardMenuTitleWithLink>
      <DashboardMenuTitleWithLink
        href='/dashboard/positions'
        isSelected={router.pathname.includes('/dashboard/positions')}
      >
        채용 공고
      </DashboardMenuTitleWithLink>
      <DashboardMenuTitleWithLink
        href='/dashboard/startup'
        isSelected={router.pathname.includes('/dashboard/startup')}
      >
        기업 상세 페이지
      </DashboardMenuTitleWithLink>
    </Row>
  )
}
