import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, BoldText, SemiBoldText } from 'components/common/text'
import moment from 'moment'
import 'moment/locale/ko'
import Linkify from 'react-linkify'
import { CHAT_SPEECH_BALLOON_WIDTH } from 'infra/constants'

export const Message: React.FCC<{
  isSending: boolean
  mainCaption?: string
  subCaption?: string
  time?: string
  isUnread?: boolean
  topItemRef?: (node: HTMLDivElement) => void
}> = ({
  isSending,
  mainCaption,
  subCaption,
  time,
  isUnread,
  topItemRef,
  children,
}) => {
  return (
    <MessageWrapper isSending={isSending} ref={topItemRef}>
      {subCaption && <SubCaption>{subCaption}</SubCaption>}
      {mainCaption && <MainCaption>{mainCaption}</MainCaption>}
      <Reverse isSending={isSending}>
        <SpeechBalloon isSending={isSending}>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target='_blank' rel='noreferrer'>
                {text}
              </a>
            )}
          >
            {children}
          </Linkify>
        </SpeechBalloon>
        <MessageInfoWrapper isSending={isSending}>
          {isUnread && <UnreadMark>1</UnreadMark>}
          {time && <TimeCaption>{moment(time).format('LT')}</TimeCaption>}
        </MessageInfoWrapper>
      </Reverse>
    </MessageWrapper>
  )
}

const MessageWrapper = styled(Column)<{ isSending: boolean }>`
  padding: 0 16px;
  gap: 8px;
  ${(p) => p.isSending && 'align-items: flex-end;'}
`

const Reverse = styled(Row)<{ isSending: boolean }>`
  align-items: flex-end;
  gap: 8px;
  ${(p) => p.isSending && 'flex-direction: row-reverse;'}
`

export const SpeechBalloon = styled.span<{ isSending: boolean }>`
  padding: 7px 12px;
  max-width: ${CHAT_SPEECH_BALLOON_WIDTH}px;
  background-color: ${(p) => (p.isSending ? '#1DA57A' : '#EEEEEE')};
  border-radius: 16px;
  color: ${(p) => (p.isSending ? p.theme.color.white1 : p.theme.color.black1)};
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
  a {
    text-decoration: underline;
  }
`

const MainCaption = styled(BoldText)`
  margin: 0 12px;
  max-width: 232px;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
`

const SubCaption = styled(MainCaption)`
  color: ${(p) => p.theme.color.gray2};
`

const MessageInfoWrapper = styled(Column)<{ isSending: boolean }>`
  gap: 4px;
  ${(p) => p.isSending && 'align-items: flex-end;'}
`

const TimeCaption = styled(BaseText)`
  font-size: 12px;
  font-weight: ${(p) => p.theme.fontWeight.light};
  color: ${(p) => p.theme.color.gray2};
  line-height: 12px;
`

const UnreadMark = styled(SemiBoldText)`
  font-size: 12px;
  color: ${(p) => p.theme.color.primary};
  line-height: 12px;
`
