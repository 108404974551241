import React from 'react'
import { Tag, TagContainer } from 'components/common/tag'
import {
  DashboardScoutRequestListQueryParams,
  ScoutRequestFormDataKey,
} from 'types/common'
import { addHighlight } from 'utils/displays'

export const ConditionTags: React.FC<{
  data: { dataKey: ScoutRequestFormDataKey; value: string }[]
  params?: DashboardScoutRequestListQueryParams
}> = ({ data, params }) => {
  return (
    <TagContainer>
      {data.map((cond) => {
        if (!cond.value.trim()) return null
        if (params) {
          if (
            cond.dataKey === 'salary' &&
            (params?.maxSalary !== 10000 || params.minSalary !== 0)
          ) {
            cond.value = addHighlight(cond.value)
          }
          if (cond.dataKey === 'locations' && !!params.locations.length) {
            cond.value = addHighlight(cond.value)
          }
          if (
            cond.dataKey === 'fundingRounds' &&
            !!params.fundingRounds.length
          ) {
            cond.value = addHighlight(cond.value)
          }
          if (cond.dataKey === 'serviceAreas' && !!params.serviceAreas.length) {
            cond.value = addHighlight(cond.value)
          }
          params.keywords.forEach((keyword) => {
            cond.value = addHighlight(cond.value, keyword)
          })
        }
        return (
          <Tag
            key={cond.dataKey}
            dangerouslySetInnerHTML={{ __html: cond.value }}
          />
        )
      })}
    </TagContainer>
  )
}
