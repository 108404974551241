import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { observer } from 'mobx-react'
import { useStartupDetail } from 'apis/hooks'
import { FunctionModal } from 'components/common/page-modal'
import { useStores } from 'stores/globals'
import { StartupPositions } from 'components/startup/startup-position'
import { StartupTableInfo } from 'components/startup/startup-table-info'
import { StartupBaseInfo } from 'components/startup/startup-base-info'
import { StartupTab } from 'components/startup/startup-tab'
import { StartupDetailInfo } from 'components/startup/startup-detail-info'
import { scrollEventTrack } from 'utils/handlers'
import { NotFound } from 'components/misc/not-found'
import { viewStartup } from 'utils/handlers'

const StartupModalContent: React.FC<{ startupId: number }> = observer(
  ({ startupId }) => {
    useEffect(() => {
      viewStartup(startupId)
      const el = document.getElementById('modal-content')
      if (!el) return
      el.addEventListener('scroll', () =>
        scrollEventTrack('compDetail_jsu_scroll', el),
      )
      return () =>
        el.removeEventListener('scroll', () =>
          scrollEventTrack('compDetail_jsu_scroll', el),
        )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { data: startup, status } = useStartupDetail(startupId)
    if (status === 201)
      return (
        <NotFound
          title='기업 정보가 비공개 상태입니다.'
          desc={`기업의 요청으로 계정을 휴면상태로 변경했거나\n계약이 만료되었습니다.`}
        />
      )
    if (!startup) return null
    const { curr } = startup
    const PAGE_SIZE = 860

    return (
      <Column style={{ gap: 40, marginBottom: 240 }}>
        <StartupDetailHeader>
          <StartupDetailSection>
            <StartupBaseInfo data={curr} />
          </StartupDetailSection>
        </StartupDetailHeader>
        <StartupDetailSection style={{ marginBottom: 20 }}>
          <StartupTableInfo data={curr} />
          <div style={{ height: 32 }} />
          <StartupPositions data={curr} />
          <StartupTab isModal={true} />
          <StartupDetailInfo data={curr} width={PAGE_SIZE} />
        </StartupDetailSection>
      </Column>
    )
  },
)
export const StartupModal: React.FC = observer(() => {
  const { startupModalStore } = useStores()
  if (!startupModalStore.isOpen) return null

  return (
    <FunctionModal
      width={1060}
      pageLink={`/startups/${startupModalStore.id}`}
      clickPageLink={() => {
        startupModalStore.closeModal()
      }}
      onClose={() => startupModalStore.closeModal()}
    >
      <React.Suspense>
        <StartupModalContent startupId={startupModalStore.id} />
      </React.Suspense>
    </FunctionModal>
  )
})

const StartupDetailHeader = styled(Row)`
  border-bottom: 1px solid ${(p) => p.theme.color.line};
  padding: 40px 0 20px;
`

const StartupDetailSection = styled(Column)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 860px;
    margin: 0 auto;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    padding: 0 20px;
  }
`
