//데이터 가공 함수 처리

import moment from 'moment'
import {
  Condition,
  DashboardScoutProposal,
  IdpCertificateStatus,
  Range,
  ScoutFulfillment,
  ScoutProposal,
  ScoutRequestDataKeyIsRequired,
  ScoutRequestMiscOptions,
  TechStackWithPositionTypes,
  WithIdName,
  DashboardChatStatusId,
  Application,
  DashboardApplication,
  DashboardChatStatusType,
  ScoutRequestFormDataKey,
  TrackingHireAlertLevel,
  AdminMatchingListChatStatus,
  DashboardScoutRequestListQueryParams,
  CareerHistory,
  OtherActivityHistory,
  EducationHistory,
  WithIdNameParentId,
  WithIdNameNullableParentId,
} from 'types/common'
import { theme } from 'infra/theme'
import {
  addHighlight,
  calcPublishedDate,
  displaySalaryNegotiableWithStockOption,
  getDevCountCondition,
  getMemberCountCondition,
  getSalaryCondition,
} from 'utils/displays'
import { TagType } from 'components/common/tag'
import { WITH_HTTP_ADDRESS } from 'infra/constants'

const today = new Date()

export const getChatStatusTagType = (
  status: DashboardChatStatusType | AdminMatchingListChatStatus,
) => {
  switch (status) {
    case DashboardChatStatusType.INPROGRESS:
      return TagType.GREEN
    case DashboardChatStatusType.CONTACTLATER:
      return TagType.YELLOW
    case DashboardChatStatusType.BREAKDOWN:
      return TagType.RED
    case DashboardChatStatusType.SUCCEEDED:
      return TagType.BLUE
    default: // AdminMatchingListChatStatus.WAITING 일 때만 해당함
      return TagType.GREEN
  }
}

export const getChatStatusDescription = (
  value: DashboardChatStatusId,
  isProposalAccepted: boolean | null, // 채팅 수락 여부 (과거에 accept 메시지 없이 채팅 수락된 경우, acceptedAt 없기 때문에 필요) / 스카우트가 아니라서 값 없으면 null
  proposalAcceptedAt?: string | null, // 채팅 수락
  resumeAcceptedAt?: string | null,
  isRejected?: boolean,
) => {
  if (
    value !== DashboardChatStatusType.BREAKDOWN &&
    value !== DashboardChatStatusType.INPROGRESS
  )
    return ''
  if (value === DashboardChatStatusType.BREAKDOWN && isRejected)
    return '(채팅종료)'
  if (
    value === DashboardChatStatusType.INPROGRESS &&
    isProposalAccepted === false
  )
    return '(수락 전)'
  if (value === DashboardChatStatusType.INPROGRESS && proposalAcceptedAt)
    return `(${calcPublishedDate(proposalAcceptedAt)} 수락)`
  if (value === DashboardChatStatusType.INPROGRESS && resumeAcceptedAt)
    return `(${calcPublishedDate(resumeAcceptedAt)} 서류합격)`
  return ''
}

export const displayTrackingHireAlertLevel = (
  alertLevel: TrackingHireAlertLevel | null,
) => {
  if (!alertLevel) return ''
  if (alertLevel === 1) return 'Level 1'
  if (alertLevel === 2) return 'Level 2'
  if (alertLevel === 3) return '운영팀 직접 연락'
  return ''
}

export const formatRelativePastDate = (date: string | null) =>
  date ? (getDiffDays(date) > 0 ? `${getDiffDays(date)}일 전` : '오늘') : '-'

// 24시간 단위가 아니라, 두 날짜의 자정을 기준으로 일수를 계산함 (예. 1/1 6:00와 1/3 2:00 사이의 diff days는 2)
export const getDiffDays = (past: string) => {
  const pastDate = new Date(past)
  pastDate.setHours(0, 0, 0, 0)
  const nowDate = new Date()
  nowDate.setHours(0, 0, 0, 0)
  const diff = moment(nowDate).diff(pastDate, 'days')
  return diff
}

export const getDiffFromPast = (past: string) => {
  const pastDate = new Date(past)
  const diff = Math.floor(
    (today.getTime() - pastDate.getTime()) / (1000 * 3600 * 24),
  )
  return diff
}

export const getConditionSatisfactionPercentage = (
  conditions: Condition[],
  fulfillments: ScoutFulfillment[],
) => {
  return Math.floor(
    (conditions.filter((condition) =>
      fulfillments.map((item) => item.dataKey).includes(condition.dataKey),
    ).length /
      conditions.length) *
      100,
  )
}

export const getYears = () => {
  const year = moment().get('year')
  const years: WithIdName[] = []
  for (let i = 0; i < 60; i++) {
    years.push({ id: i + 1, name: String(year - i) })
  }
  return years
}

export const getPercentage = (n: number) => Math.round(n * 100)

export const getFitTechStacks = (
  positionIds: number[],
  techStacks: TechStackWithPositionTypes[],
) => {
  if (positionIds.length === 0) return techStacks
  const positionTypesSet = new Set(positionIds)
  return [...techStacks].sort((a, b) => {
    const isAFit =
      a.positionTypes && a.positionTypes.some((x) => positionTypesSet.has(x))
    const isBFit =
      b.positionTypes && b.positionTypes.some((x) => positionTypesSet.has(x))
    if (isAFit && !isBFit) return -1
    else if (!isAFit && isBFit) return 1
    return 0
  })
}

export const getAddedDays = (days: number) => {
  const result = new Date()
  result.setDate(result.getDate() + days)
  return result
}

export const getIdpCertificateStatusType = (status?: IdpCertificateStatus) => {
  const idpCertificateStatusTypes = {
    pending: {
      description: '인증 대기중',
      color: '#006C4D',
      tooltip: '인증이 완료되면 카카오톡으로 알려드려요!',
    },
    failed: {
      description: '인증 실패',
      color: theme.color.alert,
      tooltip: '배지 인증이 반려되었어요 :(',
    },
    notFilled: {
      description: '정보 미작성',
      color: '#FF8B64',
      tooltip: '경험을 증명할 소개 정보가 없는 상태에요 :(',
    },
  }
  if (!status) return undefined
  if (status.isCertified === null) return idpCertificateStatusTypes.pending
  if (!status.isCertified) return idpCertificateStatusTypes.failed
  if (!status.isContentFilled) return idpCertificateStatusTypes.notFilled
  return null
}

interface GetConditionParams {
  profile: {
    salary: Range | null
    devCount: Range | null
    memberCount: Range | null
    locations: string[] | null
    serviceAreas: string[] | null
    fundingRounds: string[] | null
    miscOptions: ScoutRequestMiscOptions | null
    requiredConditions: ScoutRequestDataKeyIsRequired[] | null
  }
  isRequired: boolean
}

export const getConditions = ({ profile, isRequired }: GetConditionParams) => {
  const result: { dataKey: ScoutRequestFormDataKey; value: string }[] = []
  profile.requiredConditions?.forEach((cond) => {
    if (cond.isRequired === isRequired) {
      switch (cond.dataKey) {
        case 'devCount':
          if (profile.devCount?.min === 0 && profile.devCount.max === 10) return
          result.push({
            dataKey: cond.dataKey,
            value: getDevCountCondition(
              profile.devCount?.min || 0,
              profile.devCount?.max || 0,
            ),
          })
          break
        case 'fundingRounds':
          if (!profile.fundingRounds?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.fundingRounds.join(' ‧ '),
          })
          break
        case 'locations':
          if (!profile.locations?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.locations.join(' ‧ '),
          })
          break
        case 'memberCount':
          if (profile.memberCount?.min === 0 && profile.memberCount.max === 20)
            return
          result.push({
            dataKey: cond.dataKey,
            value: getMemberCountCondition(
              profile.memberCount?.min || 0,
              profile.memberCount?.max || 0,
            ),
          })
          break
        case 'salary':
          if (profile.salary?.min === 0 && profile.salary.max === 10000) return
          result.push({
            dataKey: cond.dataKey,
            value: getSalaryCondition(
              profile.salary?.min || 0,
              profile.salary?.max || 0,
            ),
          })
          break
        case 'serviceAreas':
          if (!profile.serviceAreas?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.serviceAreas.join(' ‧ '),
          })
          break
        case 'shouldCto':
          if (!profile.miscOptions?.shouldCto) return
          result.push({ dataKey: cond.dataKey, value: 'CTO 있음' })
          break
        case 'shouldSupervisor':
          if (!profile.miscOptions?.shouldSupervisor) return
          result.push({ dataKey: cond.dataKey, value: '사수 있음' })
          break
        case 'remoteWorkPreference':
          if (!profile.miscOptions?.remoteWorkPreference) return
          result.push({
            dataKey: cond.dataKey,
            value:
              profile.miscOptions.remoteWorkPreference ===
              'FULL_REMOTE_NECESSARY'
                ? '원격 근무만 가능'
                : '부분 원격 근무 필요',
          })
          break
        case 'shouldStockOption':
          if (!profile.miscOptions?.shouldStockOption) return
          result.push({
            dataKey: cond.dataKey,
            value: displaySalaryNegotiableWithStockOption(
              profile.miscOptions.isSalaryNegotiableWithStockOption,
            ),
          })
          break
        default:
          break
      }
    }
  })
  return result
}

export const getProposalChatList = (
  proposals: Partial<DashboardScoutProposal & ScoutProposal>[],
) => {
  return proposals.map((item) => {
    return {
      name: item.name || '',
      startupId: item.startupId,
      requestId: item.requestId,
      proposalId: item.proposalId || null,
      applicationId: null,
      lastMessage: item.lastMessage || null,
      unreadMessagesCount: item.unreadMessagesCount || 0,
      image: item.thumbnail || item.profileImage || '',
      isRejected: item.isRejected,
      isCanceled: item.isCanceled,
      hasConfirmedMeet: item.hasConfirmedMeet,
      hasDoneMeet: item.hasDoneMeet,
      status: item.status,
      chatStep: item.chatStep,
      acceptedAt: item.acceptedAt,
      shouldBrokingScoutProposal: item.shouldBrokingScoutProposal,
    }
  })
}

export const getApplicationChatList = (
  applications: Partial<DashboardApplication & Application>[],
) => {
  return applications.map((item) => {
    return {
      name: item.user?.name || item.startup?.name || '',
      requestId: item.scoutRequestId || item.scoutRequest?.id,
      proposalId: null,
      startupId: item.startup?.id,
      applicationId: item.id || null,
      lastMessage: item.lastMessage || null,
      unreadMessagesCount: item.unreadMessagesCount || 0,
      image: item.user?.image || item.startup?.thumbnail || '',
      statusId: item.statusId,
      chatStep: item.chatStep,
      resumeAcceptedAt: item.resumeAcceptedAt,
      rejectedAt: item.rejectedAt,
    }
  })
}

export function getUrlLastSegment(url: string) {
  return url.split('/').pop() || ''
}

export const getUrlWithHttp = (url: string) =>
  !WITH_HTTP_ADDRESS.test(url) ? `http://${url}` : url

interface HighlightProfile {
  positions: WithIdName[]
  techStacks: WithIdName[]
  careers: CareerHistory[]
  otherActivities: OtherActivityHistory[]
  summary: { title: string; content: string }[] | null
  educations: EducationHistory[]
}

export const highlightProfileItem = (
  profile: HighlightProfile,
  params: DashboardScoutRequestListQueryParams,
): HighlightProfile => {
  const positions = profile.positions.map((position) => {
    params.keywords.forEach((keyword) => {
      position.name = addHighlight(position.name || '', keyword)
    })
    return {
      ...position,
      name: params.positions.includes(position.id)
        ? addHighlight(position.name)
        : position.name,
    }
  })
  const techStacks = profile.techStacks.map((techStack) => {
    params.keywords.forEach((keyword) => {
      techStack.name = addHighlight(techStack.name || '', keyword)
    })
    return {
      ...techStack,
      name: params.techStacks.includes(techStack.id)
        ? addHighlight(techStack.name)
        : techStack.name,
    }
  })
  const careers = profile.careers.map((career) => {
    params.keywords.forEach((keyword) => {
      career.position = addHighlight(career.position || '', keyword)
      career.description = addHighlight(career.description || '', keyword)
      career.company.name = addHighlight(career.company.name || '', keyword)
    })
    const techStacks = career.techStacks.map((techStack) => {
      params.keywords.forEach((keyword) => {
        techStack.name = addHighlight(techStack.name || '', keyword)
      })
      return {
        ...techStack,
        name: params.techStacks.includes(techStack.id)
          ? addHighlight(techStack.name)
          : techStack.name,
      }
    })
    return {
      ...career,
      techStacks,
    }
  })
  const otherActivities = profile.otherActivities.map((otherActivity) => {
    params.keywords.forEach((keyword) => {
      otherActivity.title = addHighlight(otherActivity.title || '', keyword)
      otherActivity.description = addHighlight(
        otherActivity.description || '',
        keyword,
      )
    })
    const techStacks = otherActivity.techStacks.map((techStack) => {
      params.keywords.forEach((keyword) => {
        techStack.name = addHighlight(techStack.name || '', keyword)
      })
      return {
        ...techStack,
        name: params.techStacks.includes(techStack.id)
          ? addHighlight(techStack.name)
          : techStack.name,
      }
    })
    return {
      ...otherActivity,
      techStacks,
    }
  })
  const summary = profile.summary
    ? profile.summary.map((_item) => {
        let title = _item.title
        params.keywords.forEach(
          (keyword) => (title = addHighlight(title, keyword)),
        )
        let content = _item.content
        params.keywords.forEach(
          (keyword) => (content = addHighlight(content, keyword)),
        )
        return { title, content }
      })
    : null
  const educations = profile.educations.map((education) => {
    params.keywords.forEach((keyword) => {
      education.major = addHighlight(education.major || '', keyword)
      education.school.name = addHighlight(education.school.name || '', keyword)
      if (education.category) {
        education.category.name = addHighlight(
          education.category.name || '',
          keyword,
        )
      }
    })
    return education
  })
  return {
    positions,
    techStacks,
    careers,
    otherActivities,
    summary,
    educations,
  }
}

// 상위 분류가 있을 때 하위 필터 선택 시 하위 필터의 출처를 보여주기 위함
export const getSelectedChildrenByParent = (
  data: WithIdNameParentId[],
  selectedData: WithIdNameNullableParentId[],
) => {
  const parentData = data.filter((item) => !item.parentId)
  return parentData.map((parent) => {
    return {
      id: parent.id,
      name: parent.name,
      parentId: parent.parentId,
      total: data.filter((item) => item.parentId === parent.id).length || 0,
      selectedChildren:
        selectedData?.filter((child) => child.parentId === parent.id) || [],
    }
  })
}

export const getFlattenedData = (
  data: WithIdNameParentId[],
  selectedData: WithIdNameParentId[],
) => {
  const selectedChildrenOfParent = getSelectedChildrenByParent(
    data,
    selectedData,
  )

  const flattenedData = selectedChildrenOfParent
    .map((parent) => {
      if (parent.total === parent.selectedChildren.length)
        return `${parent.name} 전체`
      return parent.selectedChildren.map(
        (child) => `${parent.name} ${child.name}`,
      )
    })
    .flat()
    .filter((item) => item && item.length > 0)

  return flattenedData
}
