import React from 'react'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Column } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import styled from 'styled-components'
import { BaseText, H5Text, SemiBoldText } from 'components/common/text'
import { InformationBox } from 'components/misc/information-box'
import { StartupPositionDetail } from 'types/common'
import moment from 'moment'
import { theme } from 'infra/theme'
import { getAddedDays } from 'utils/formatters'
import { CheckCircleIcon } from 'components/common/icon'
import { useIsDesktopCSR } from 'utils/hooks'

export const ApplyDone: React.FC<{
  data: StartupPositionDetail
  onClose: () => void
}> = ({ data, onClose }) => {
  const isDesktop = useIsDesktopCSR()
  return (
    <ContentBox>
      <Column style={{ alignItems: 'center' }}>
        <CheckCircleIcon />
        <PageTitle>지원을 완료했어요!</PageTitle>
        <ExplainText>
          채용 담당자의 검토 결과는 <br />
          <span style={{ fontWeight: theme.fontWeight.bold }}>
            {moment(getAddedDays(7)).format('M월 D일')}
          </span>
          까지 알림톡으로 안내드려요.
          <br />
          좋은 결과 있으시길 바랍니다.
        </ExplainText>
      </Column>
      <ApplyInfo>
        <ApplyInfoRow>
          <PositionText>스타트업명</PositionText>
          <PositionText>{data.startup.name}</PositionText>
        </ApplyInfoRow>
        <ApplyInfoRow>
          <PositionText>포지션 · 경력</PositionText>
          <PositionText>
            {data.name} · {data.careerType}
          </PositionText>
        </ApplyInfoRow>
      </ApplyInfo>
      <Column style={{ gap: 8 }}>
        <InformationBox>
          지원 메시지를 수정하려면 지원 취소 후 다시 지원해주세요. 이력서 열람
          전까지 지원을 취소할 수 있어요.
        </InformationBox>
      </Column>
      <Column style={{ gap: 8 }}>
        <MyLink href='/positions/my-activities' onClick={onClose}>
          <Button size={isDesktop ? undefined : BtnSize.SMALL}>
            지원 정보 보기
          </Button>
        </MyLink>
        <MyLink href='/positions' onClick={onClose}>
          <Button
            variant={BtnType.OUTLINE}
            size={isDesktop ? undefined : BtnSize.SMALL}
          >
            다른 채용 공고 둘러보기
          </Button>
        </MyLink>
      </Column>
    </ContentBox>
  )
}

const PositionText = styled(H5Text)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const ContentBox = styled(Column)`
  gap: 40px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 32px;
  }
`

const PageTitle = styled(SemiBoldText)`
  margin: 24px 0 32px;
  font-size: ${(p) => p.theme.fontSize.h3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

const ExplainText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray1};
  line-height: 24px;
  text-align: center;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    line-height: 19.5px;
  }
`

const ApplyInfo = styled(Column)`
  padding: 24px 0;
  gap: 16px;
  border-top: 1px solid ${(props) => props.theme.color.gray3};
  border-bottom: 1px solid ${(props) => props.theme.color.gray3};
`

const ApplyInfoRow = styled.div`
  display: grid;
  grid-template-columns: 182px 1fr;
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`
