import React, { useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { TriggerExceptTarget } from 'components/misc/trigger'
import { DashboardDropdownMenu } from 'components/misc/header/dashboard/dashboard-dropdown-menu'
import {
  MobileTabMenu,
  MobileTabMenuContainer,
  MobileTabMenuText,
} from 'components/misc/header/styles'
import { Chat } from 'components/misc/header/dashboard/dashboard-chat'
import { AlertBadge } from 'components/common/badge'
import { useDashboardApplicationsStatus } from 'apis/hooks'

export const DashboardMobileHeaderTabs = () => {
  const { data: applicationStatus } = useDashboardApplicationsStatus()
  const router = useRouter()
  const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false)
  const isScoutSelected = router.asPath.includes('/scouts')
  const isApplicantSelected = router.asPath.includes('/applicants')
  const isPositionSelected = router.asPath.includes('/positions')
  const isMoreSelected =
    router.asPath.includes('/startup') ||
    router.asPath.includes('/account') ||
    router.asPath.includes('/pricing')
  const isChatSelected = router.asPath.includes('/chat-rooms')

  return (
    <MobileTabMenuContainer style={{ gap: 16 }}>
      <MobileTabMenu
        onClick={() => router.push('/dashboard/scouts/search/new-profile')}
      >
        <DashboardMobileTabMenuText isSelected={isScoutSelected}>
          스카우트
        </DashboardMobileTabMenuText>
      </MobileTabMenu>
      <MobileTabMenu onClick={() => router.push('/dashboard/applicants')}>
        <DashboardMobileTabMenuText
          isSelected={isApplicantSelected}
          style={{ position: 'relative' }}
        >
          {!!applicationStatus?.[0].count && (
            <AlertBadge size={10} top={-4} right={-6} />
          )}
          지원자
        </DashboardMobileTabMenuText>
      </MobileTabMenu>
      <MobileTabMenu onClick={() => router.push('/dashboard/positions')}>
        <DashboardMobileTabMenuText isSelected={isPositionSelected}>
          채용 공고
        </DashboardMobileTabMenuText>
      </MobileTabMenu>
      <TriggerExceptTarget onTrigger={() => setIsMoreDropdownOpen(false)}>
        <MobileTabMenu onClick={() => setIsMoreDropdownOpen((prev) => !prev)}>
          <DashboardMobileTabMenuText isSelected={isMoreSelected}>
            더보기
          </DashboardMobileTabMenuText>
          {isMoreDropdownOpen && <DashboardDropdownMenu />}
        </MobileTabMenu>
      </TriggerExceptTarget>
      <MobileTabMenu>
        <DashboardMobileTabMenuText isSelected={isChatSelected}>
          <Chat />
        </DashboardMobileTabMenuText>
      </MobileTabMenu>
    </MobileTabMenuContainer>
  )
}

const DashboardMobileTabMenuText = styled(MobileTabMenuText)`
  height: 40px;
  font-size: ${(p) => p.theme.fontSize.default};
  min-width: auto;
`
