import React from 'react'
import styled from 'styled-components'
import { useIsLoggedInStartup } from 'utils/hooks'
import { ResponsiveDisplay } from 'components/common/layout'
import { Row } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { H4Text } from 'components/common/text'
import { DASHBOARD_DEFAULT_URL } from 'infra/constants'
import { theme } from 'infra/theme'

export const DashboardLogo: React.FC<{ color?: string }> = ({ color }) => {
  const isLoggedInStartup = useIsLoggedInStartup()
  return (
    <ResponsiveDisplay isDisableMobile as={Row} style={{ gap: 16 }}>
      <LogoDivision style={{ backgroundColor: color || theme.color.gray4 }} />
      <MyLink href={isLoggedInStartup ? DASHBOARD_DEFAULT_URL : '/biz'}>
        <HeaderText style={{ color: color || theme.color.primary }}>
          스타트업
        </HeaderText>
      </MyLink>
    </ResponsiveDisplay>
  )
}

const LogoDivision = styled.hr`
  width: 1px;
  height: 28px;
  border: none;
`

const HeaderText = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
`
