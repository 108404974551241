import styled from 'styled-components'
import { BaseText } from 'components/common/text'

export const HighlightedText = styled(BaseText)`
  display: inline-block;
  margin-right: 2px;
  padding: 0 4px;
  background-color: #d8efff;
  border-radius: 8px;
  color: ${(p) => p.theme.color.secondary};
  line-height: 1.4;
`
